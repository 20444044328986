var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.signin, _vm.classLayout]},[(_vm.name === 'dashboard')?[_c('Logo',{attrs:{"name":"authentication"}}),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.mailAddress),expression:"input.mailAddress"}],attrs:{"type":"email","placeholder":"メールアドレス"},domProps:{"value":(_vm.input.mailAddress)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyupEnterSignIn()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.input, "mailAddress", $event.target.value)}}})]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.password),expression:"input.password"}],attrs:{"type":"password","placeholder":"パスワード"},domProps:{"value":(_vm.input.password)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyupEnterSignIn()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.input, "password", $event.target.value)}}})]),(!_vm.loader)?_c('div',[_c('Button',{attrs:{"type":"signin"},on:{"action":function($event){return _vm.signIn()}}})],1):_vm._e()]:_vm._e(),(_vm.name === 'standard')?[_c('h1',{staticClass:"pagetitle"},[_vm._v(" サインイン ")]),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(!_vm.recaptcha.confirmationResult),expression:"!recaptcha.confirmationResult"}],ref:"observerRecaptcha",class:_vm.$style.validation,attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('p',[_vm._v("電話番号に確認コードが送信されます。")]),_c('ValidationProvider',{attrs:{"name":"電話番号","rules":"numeric|digits:11|required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputPhoneSignIn.tel),expression:"inputPhoneSignIn.tel"}],attrs:{"type":"text","placeholder":"電話番号"},domProps:{"value":(_vm.inputPhoneSignIn.tel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputPhoneSignIn, "tel", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"errorMessage"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{ref:_vm.recaptcha.refName,class:_vm.$style.recaptcha}),(!_vm.loader)?_c('div',[_c('Button',{attrs:{"disabled":_vm.phoneSignInCodeInvalid(invalid),"text":"確認コードを送信する","type":"signin"},on:{"action":function($event){return _vm.phoneSignInCode()}}})],1):_vm._e()]}}],null,false,577566050)}),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.recaptcha.confirmationResult),expression:"recaptcha.confirmationResult"}],ref:"observerPhoneSignInCode",class:_vm.$style.validation,attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('p',[_vm._v("確認コードを入力してください。")]),_c('ValidationProvider',{attrs:{"name":"確認コード","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputPhoneSignIn.code),expression:"inputPhoneSignIn.code"}],attrs:{"type":"text","placeholder":"確認コード"},domProps:{"value":(_vm.inputPhoneSignIn.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputPhoneSignIn, "code", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"errorMessage"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),(!_vm.loader)?_c('div',[_c('Button',{attrs:{"disabled":invalid,"text":"サインイン","type":"signin"},on:{"action":function($event){return _vm.phoneSignIn()}}})],1):_vm._e()]}}],null,false,765286864)})]:_vm._e(),_c('Loader',{attrs:{"loader":_vm.loader}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }