<template>
    <div :class="[$style.signin, classLayout]">
        <template v-if="name === 'dashboard'">
            <Logo
                name="authentication"
            />
            <div>
                <input v-model="input.mailAddress" type="email" placeholder="メールアドレス" @keyup.enter="keyupEnterSignIn()">
            </div>
            <div>
                <input v-model="input.password" type="password" placeholder="パスワード" @keyup.enter="keyupEnterSignIn()">
            </div>
            <div v-if="!loader">
                <Button
                    type="signin"
                    @action="signIn()"
                />
            </div>
        </template>
        <template v-if="name === 'standard'">
            <h1 class="pagetitle">
                サインイン
            </h1>
            <ValidationObserver
                v-show="!recaptcha.confirmationResult"
                ref="observerRecaptcha"
                v-slot="{ invalid }"
                :class="$style.validation"
                tag="div"
            >
                <p>電話番号に確認コードが送信されます。</p>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="電話番号"
                    rules="numeric|digits:11|required"
                    tag="div"
                >
                    <input v-model="inputPhoneSignIn.tel" type="text" placeholder="電話番号">
                    <p v-show="errors[0]" class="errorMessage">
                        {{ errors[0] }}
                    </p>
                </ValidationProvider>
                <div :ref="recaptcha.refName" :class="$style.recaptcha"></div>
                <div v-if="!loader">
                    <Button
                        :disabled="phoneSignInCodeInvalid(invalid)"
                        text="確認コードを送信する"
                        type="signin"
                        @action="phoneSignInCode()"
                    />
                </div>
            </ValidationObserver>
            <ValidationObserver
                v-show="recaptcha.confirmationResult"
                ref="observerPhoneSignInCode"
                v-slot="{ invalid }"
                :class="$style.validation"
                tag="div"
            >
                <p>確認コードを入力してください。</p>
                <ValidationProvider
                    v-slot="{ errors }"
                    name="確認コード"
                    rules="required"
                    tag="div"
                >
                    <input v-model="inputPhoneSignIn.code" type="text" placeholder="確認コード">
                    <p v-show="errors[0]" class="errorMessage">
                        {{ errors[0] }}
                    </p>
                </ValidationProvider>
                <div v-if="!loader">
                    <Button
                        :disabled="invalid"
                        text="サインイン"
                        type="signin"
                        @action="phoneSignIn()"
                    />
                </div>
            </ValidationObserver>
        </template>
        <Loader
            :loader="loader"
        />
    </div>
</template>

<script>
    "use strict";

    import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
    import * as rules from "vee-validate/dist/rules";
    import ja from "vee-validate/dist/locale/ja.json";

    import validate from "@/validate.js";

    import collection from "@/settings/collection.js";

    import Button from "@/components/Button.vue";
    import Loader from "@/components/Loader.vue";
    import Logo from "@/components/Logo.vue";

    import firebase from "@/mixins/firebase.js";
    import string from "@/mixins/string.js";

    for (const rule in rules) {
        extend(rule, {
            ...rules[rule],
            "message": ja.messages[rule],
        });
    }

    for (const key in validate) {
        extend(`${key}`, validate[key]);
    }

    export default {
        "components": {
            ValidationObserver,
            ValidationProvider,
            Button,
            Loader,
            Logo,
        },
        "mixins": [
            firebase,
            string,
        ],
        "props": {
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "input": {
                    "mailAddress": "",
                    "password": "",
                },
                "inputPhoneSignIn": {
                    "code": "",
                    "tel": "",
                },
                "loader": false,
                "recaptcha": {
                    "confirmationResult": null,
                    "invalid": true,
                    "refName": "recaptcha",
                },
            };
        },
        "computed": {
            // layouts別のclassを追加
            classLayout() {
                const adjName = this.$_initialUpperCase(this.name);
                return this.$style[`is${adjName}`];
            },
        },
        mounted() {
            if (this.name === "standard") {
                const option = {
                    "callback": () => {
                        this.recaptcha.invalid = false;
                    },
                };
                this.$_setRecaptcha(this.recaptcha.refName, option);
            }
        },
        "methods": {
            // Enterキーでサインイン
            keyupEnterSignIn() {
                let flag = false;
                for (let key in this.input) {
                    if (this.input[key]) {
                        flag = true;
                    }
                    else {
                        flag = false;
                        break;
                    }
                }
                if (flag) {
                    this.signIn();
                }
            },
            // 確認コードボタンの無効化・有効化
            phoneSignInCodeInvalid(invalid) {
                let flag = true;
                if (!invalid && !this.recaptcha.invalid) {
                    flag = false;
                }
                return flag;
            },
            // 確認コードを送信
            phoneSignInCode() {
                this.loader = true;
                this.$_phoneSignInCode(this.inputPhoneSignIn.tel).then(response => {
                    this.recaptcha.confirmationResult = response;
                    this.loader = false;
                }).catch(error => {
                    console.error(error.message);
                    this.loader = false;
                });
            },
            phoneSignIn() {
                this.loader = true;
                const collectionName = "users";
                const set = {};
                this.recaptcha.confirmationResult.confirm(this.inputPhoneSignIn.code).then(response => {
                    const uid = response.user.uid;
                    this.$_firestoreRead({
                        "collection": collectionName,
                        "doc": [uid],
                    }).then(response => {
                        const data = response.data();
                        collection[collectionName].forEach(field => {
                            set[field.field] = this.inputPhoneSignIn[field.field] ?? "";
                        });
                        if (!data) {
                            this.$_firestoreCreate({
                                "collection": collectionName,
                                "doc": [uid],
                                "set": set,
                            }).then(() => {
                                const value = {
                                    "name": this.$store.getters.getConfigs.root,
                                    "params": {
                                        "userId": uid,
                                    },
                                };
                                this.$router.push(value);
                            }).catch(error => {
                                alert(error.message);
                                this.loader = false;
                            });
                        }
                        else {
                            const value = {
                                "name": this.$store.getters.getConfigs.root,
                                "params": {
                                    "userId": uid,
                                },
                            };
                            this.$router.push(value);
                        }
                    }).catch(error => {
                        console.error(error.message);
                        this.loader = false;
                    });
                }).catch(error => {
                    alert(error.message);
                    this.loader = false;
                });
            },
            signIn() {
                this.loader = true;
                this.$_signIn(
                    this.input.mailAddress,
                    this.input.password
                ).then(() => {
                    this.$router.push({
                        "name": this.name,
                    });
                }).catch(error => {
                    alert(error.message);
                    this.loader = false;
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .signin {
        > div {
            &:not(:first-child) {
                margin-top: 32px;
            }
            > input {
                border-radius: 8px;
                font-size: 18px;
                padding: 9px;
            }
        }
        p {
            font-size: 14px;
            line-height: 1.2;
        }
        &.isDashboard {
            background: #222;
            border-radius: 24px;
            margin: 0 0 32px;
            padding: 64px;
            width: 480px;
            @include sp {
                padding: 64px 32px;
            }
        }
        &.isStandard {
            background: #fff;
            margin: 0 16px;
            padding: 16px 16px 32px;
            text-align: center;
            @include sp {
                margin-top: 16px;
            }
            .validation {
                margin-top: 0;
                > div {
                    margin-top: 32px;
                }
            }
            .recaptcha {
                display: inline-block;
            }
        }
    }
</style>
